<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <div class="alertMsg">
      <b-alert v-model="showDismissibleAlert" variant="success" dismissible>
        Employee Registered Successfully!
      </b-alert>
    </div>
    <!-- <div class="alertMsg">
      <b-alert v-model="showDismissibleAlert" variant="alertvariant" dismissible>
          {{notificationText}}
      </b-alert>
    </div> -->

    <form @submit.prevent="submitEmployee" method="post">

      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 mb-3">
                  <label class="form-label" for="formrow-empName-input">Name *</label>
                  <input type="text" class="form-control" id="formrow-name-input" v-model="empName"
                         :class="{
                                'is-invalid': submitted && $v.empName.$error,
                            }"
                  >
                  <div
                      v-if="submitted && $v.empName.$error"
                      class="invalid-feedback"
                  >
                            <span v-if="!$v.empName.required"
                            >This value is required.</span
                            >
                  </div>
                </div>


                <div class=" col-6">
                  <label class="form-label" for="formrow-empEmailID-input">EmailID *</label>
                  <input type="text" class="form-control" id="formrow-empEmailID-input" v-model="empEmailID"
                         :class="{
                                    'is-invalid': submitted && $v.empEmailID.$error,
                                }"
                  >
                  <div
                      v-if="submitted && $v.empEmailID.$error"
                      class="invalid-feedback"
                  >
                                <span v-if="!$v.empEmailID.required"
                                >This value is required.</span
                                >
                  </div>
                </div>
                <div class="col-6">
                  <label class="form-label">Phone No.*</label>
                  <input class="form-control" type="number" min="0"
                         im-insert="true" v-model="phoneNO"
                         :class="{
                                'is-invalid': submitted && $v.phoneNO.$error,
                            }">

                  <div
                      v-if="submitted && $v.phoneNO.$error"
                      class="invalid-feedback"
                  >
                            <span v-if="!$v.phoneNO.required"
                            >This value is required.</span
                            >
                    <span v-if="!$v.phoneNO.maxLength"
                    >This value is too long. It should have 10 digits</span
                    >
                    <span v-if="!$v.phoneNO.minLength"
                    >This value length is invalid. It should be
                              10 digits</span
                    >
                  </div>
                </div>
                <div class="mb-3 col-6">
                  <label> Login Type<sup>*</sup> : </label>
                  <multiselect
                      v-model="loginType"
                      :options="loginTypeArr"
                      :show-labels="false"
                      track-by="id"
                      label="name"
                      :class="{'is-invalid': submitted && $v.loginType.$error,}"
                      @input="loadRoleTypeData()"
                  >
                  </multiselect>
                  <div v-if="submitted && $v.loginType.$error" class="invalid-feedback">
                    <span v-if="!$v.loginType.required">This value is required.</span>
                  </div>
                </div>
                <div class="mb-3 col-6">
                  <label> Role<sup>*</sup> :</label>
                  <multiselect
                      v-model="role"
                      :options="roles"
                      :show-labels="false"
                      track-by="id"
                      label="name"
                      :class="{'is-invalid': submitted && $v.loginType.$error,}"
                  >
                  </multiselect>
                  <div v-if="submitted && $v.role.$error" class="invalid-feedback">
                    <span v-if="!$v.role.required">This value is required.</span>
                  </div>
                </div>
           <div class="col-md-6 mb-3" v-if="editMode">
             <label>Remove from this branch</label>
             <br />
             <div class="form-check form-switch form-switch-md mb-3" dir="ltr">
               <input type="checkbox" class="form-check-input" id="customSwitchsizemd" v-model="removeFromBranch">
             </div>
         </div>
              </div>


              <!-- <div class="col-md-4 mb-3">
                  <label>Date of Joining</label>
                  <br />
                  <input type="date" class="form-control" id="formrow-licenseNO-input" v-model="dateJoining">

              </div> -->
              <div class=" row" style="float:right;">
                <b-spinner v-if="loading"
                           class="m-2 col-3"
                           variant="primary"
                           role="status"
                ></b-spinner>
                <button type="submit" class="col-3 btn btn-themeYellow w-md waves-effect waves-light"
                        :disabled="loading">Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

  </Layout>
</template>
<script>
import Multiselect from "vue-multiselect";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import {
  required, email, maxLength, minLength
} from "vuelidate/lib/validators";


/**
 * Advanced-form component
 */
export default {
  page: {
    title: "Add Principle User",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Multiselect,
    Layout,
    PageHeader,

  },
  data() {
    return {
      title: "Add Principle User",
      items: [
        {
          text: "Add Principle User",
          href: "/",
        },
        {
          text: "Add",
          active: true,
        },
      ],

      status: '',
      submitted: false,
      showDismissibleAlert: false,
      loading: false,
      notificationText: "",
      alertvariant: "",
      //dateJoining:"",
      employeeTypeArr: [],
      empTypeID: "",
      empName: '',
      empEmailID: '',
      phoneNO: '',
      loginTypeArr: [
        {id: 1, name: "CloudKitch Admin"},
        {id: 2, name: "Restaurant"},
        {id: 3, name: "Corporate"},
        {id: 4, name: "Principle"},
      ],
      loginType: "",
      roles: [],
      role: "",
      editMode: false,
      removeFromBranch:false,

    };
  },

  validations: {
    empName: {
      required,
    },
    empEmailID: {
      required,
      email,
    },
    phoneNO: {
      required,
      maxLength: maxLength(10),
      minLength: minLength(10),
    },
    loginType: {
      required
    },
    role: {
      required
    }

  },
  created() {
    let principle_branch_id = this.$route.params.principle_branch;
    if (principle_branch_id) {
      sessionStorage.setItem('principle_branch', principle_branch_id);// Save to sessionStorage
    }
    let principle_id = this.$route.params.principle_id;
    if (principle_id) {
      sessionStorage.setItem('principle_id', principle_id);// Save to sessionStorage
    }
    if (this.$route.params.type == 'edit' && this.$route.params.admin_id) {
      this.editMode = true;
      sessionStorage.setItem('pam', this.$route.params.admin_id);
    }
  },
  methods: {
    loadRoleTypeData() {
      if (this.loginType) {
        this.loading = true;
        this.axios
            .get(this.$loggedRole + "/login-type-roles/" + this.loginType.id)
            .then((result) => {
              this.roles = result.data.data;
              this.loading = false;
            });

      }
    },
    submitEmployee(e) {

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true;
        let api;
        let formData = new FormData();
        if (this.editMode) {
          api = this.$loggedRole + "/updatePrincipleAdmin";
          formData.append('admin_id', sessionStorage.getItem('pam'));
          formData.append('untag_branch',this.removeFromBranch);
        } else {
          api = this.$loggedRole + "/addPrincipleAdmin"
        }
        formData.append('emailID', this.empEmailID);
        formData.append('phoneNO', this.phoneNO);
        formData.append('name', this.empName);
        formData.append('principle_branch', sessionStorage.getItem('principle_branch'));
        formData.append('principle_id', sessionStorage.getItem('principle_id'));
        formData.append('login_type', this.loginType.id);
        formData.append('role', JSON.stringify(this.role));
        e.preventDefault();
        this.axios.post(api, formData)
            .then((result) => {
              this.loading = false;
              this.$swal({
                text: result.data.message,
                icon: "success",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000
              });
              setTimeout(() => {
                this.$router.back();
              }, 2000);
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: error.response.data.message,
                icon: "error",
              });
            });
      }
    },
    getEmployeeDetail() {
      if (sessionStorage.getItem('pam') && sessionStorage.getItem('principle_branch')) {
        this.loading = true;
        this.axios
            .get(this.$loggedRole + "/get-principle-admin/"+sessionStorage.getItem('pam')+"/" + sessionStorage.getItem('principle_id')+"/"+sessionStorage.getItem('principle_branch'))
            .then((result) => {
              this.loading = false;
              this.empName = result.data.data.name;
              this.empEmailID = result.data.data.adminEmailID;
              this.phoneNO = result.data.data.adminMobileNO;
              this.loginType = result.data.data.login_type;
              this.loadRoleTypeData();
              this.role = result.data.data.role;
            });
      }
    },

  },

  mounted() {
    let principle_branch_id = this.$route.params.principle_branch;
    if (principle_branch_id) {
      sessionStorage.setItem('principle_branch', principle_branch_id);// Save to sessionStorage
    }
    let principle_id = this.$route.params.principle_id;
    if (principle_id) {
      sessionStorage.setItem('principle_id', principle_id);// Save to sessionStorage
    }
    if (this.$route.params.type == 'edit' && sessionStorage.getItem('pam') && sessionStorage.getItem('principle_id') && sessionStorage.getItem('principle_branch')) {
      this.editMode = true;

      this.getEmployeeDetail();
    }
  },
  middleware: "authentication",
};
</script>
<style lang="scss" scoped>

img.preview {
  width: 100px;
  height: 100px;
  background-color: white;
  border: 1px solid #DDD;
  padding: 5px;
  object-fit: contain;
  margin-top: 6px;
}

.alertMsg {
  position: fixed;
  right: 0px;
  z-index: 500;
  top: 79px;
  width: 22%;
}
</style>


